export default function initUpcomingEvents() {
    document.addEventListener('DOMContentLoaded', function () {
        const grid = document.getElementById('events-grid');
        const loadMoreButton = document.getElementById('load-more-events');
        let currentPage = 1;
        const perPage = 6;
        if (!grid || !loadMoreButton)
            return;
        const events = [...grid.children];
        if (events.length <= perPage) {
            loadMoreButton.style.display = 'none';
            return;
        }
        loadMoreButton.addEventListener('click', function () {
            const nextEvents = events.slice(currentPage * perPage, (currentPage + 1) * perPage);
            nextEvents.forEach((event) => event.classList.remove('hidden'));
            currentPage++;
            if (currentPage * perPage >= events.length) {
                loadMoreButton.style.display = 'none';
            }
        });
    });
}
