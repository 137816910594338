export default function initHubTabs() {
    const tabButtons = document.querySelectorAll('.hub-tab-button');
    const tabContents = document.querySelectorAll('.hub-tab-content');
    // Ensure the first tab is active on page load
    if (tabButtons.length > 0 && tabContents.length > 0) {
        activateTab(tabButtons[0], tabContents[0]);
    }
    tabButtons.forEach((button) => {
        button.addEventListener('click', () => {
            const targetId = button.getAttribute('data-tab-target');
            const targetContent = document.getElementById(targetId);
            if (targetContent) {
                deactivateAllTabs();
                activateTab(button, targetContent);
            }
        });
    });
    function deactivateAllTabs() {
        tabButtons.forEach((btn) => {
            btn.classList.remove('bg-[#609fed]', 'text-white', 'font-bold', 'border-black');
            btn.classList.add('bg-white', 'text-[#131313]', 'border-transparent');
        });
        tabContents.forEach((content) => {
            content.classList.add('opacity-0');
            setTimeout(() => {
                content.classList.add('hidden');
            }, 300);
        });
    }
    function activateTab(button, content) {
        button.classList.remove('bg-white', 'text-[#131313]', 'border-transparent');
        button.classList.add('bg-[#609fed]', 'text-white', 'font-bold', 'border-black');
        setTimeout(() => {
            content.classList.remove('hidden', 'opacity-0');
            content.classList.add('opacity-100');
        }, 300);
    }
}
