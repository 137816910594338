// type AjaxObject = {
//   ajax_url: string;
//   nonce: string;
// };
// type EventData = {
//   html: string;
//   error?: string;
// };
// type EventResponse = {
//   success: boolean;
//   data: EventData;
// };
// declare const ajax_obj: AjaxObject;
// export default function initEventCalendar() {
//   const grid = document.getElementById('events-grid') as HTMLElement | null;
//   const loadMoreButton = document.getElementById(
//     'load-more-events'
//   ) as HTMLButtonElement | null;
//   const dropdown = document.getElementById(
//     'event-type-filter'
//   ) as HTMLSelectElement | null;
//   const buttons = document.querySelectorAll<HTMLButtonElement>(
//     '.filter-controls button'
//   );
//   if (!grid || !loadMoreButton || !dropdown) {
//     console.error('Missing required DOM elements for event calendar.');
//     return;
//   }
//   const updateButtonState = (button: HTMLButtonElement, isActive: boolean) => {
//     button.classList.toggle('active', isActive);
//     button.classList.toggle('bg-[#ffaeae]', isActive);
//     button.classList.toggle('text-white', isActive);
//     button.classList.toggle('border-[#de212a]', !isActive);
//     button.classList.toggle('text-black', !isActive);
//   };
//   const resetButtonStyles = () => {
//     buttons.forEach((btn) => updateButtonState(btn, false));
//   };
//   const loadEvents = async (page = 1, append = false, loadAll = false) => {
//     const selectedType = dropdown.value;
//     const selectedMembership =
//       document.querySelector<HTMLButtonElement>(
//         '.filter-controls button.active'
//       )?.dataset.filter || '';
//     try {
//       grid.innerHTML = append ? grid.innerHTML : 'Loading...';
//       if (append) loadMoreButton.textContent = 'Loading...';
//       const params = new URLSearchParams({
//         action: 'load_more_events',
//         nonce: ajax_obj.nonce,
//         page: page.toString(),
//         event_type: selectedType,
//         membership: selectedMembership,
//         all: loadAll ? '1' : '0',
//       });
//       const response = await fetch(ajax_obj.ajax_url, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//         body: params.toString(),
//       });
//       const result: EventResponse = await response.json();
//       if (result.success) {
//         grid.innerHTML = append
//           ? grid.innerHTML + result.data.html
//           : result.data.html;
//         if (loadAll || !result.data.html.trim()) {
//           loadMoreButton.style.display = 'none';
//         } else {
//           loadMoreButton.dataset.page = (page + 1).toString();
//           loadMoreButton.textContent = 'Load More Events';
//           loadMoreButton.style.display = 'block';
//         }
//       } else {
//         console.error('Error:', result.data.error);
//         grid.innerHTML = 'Failed to load events.';
//       }
//     } catch (error) {
//       console.error('Error loading events:', error);
//       grid.innerHTML = 'Failed to load events.';
//     }
//   };
//   // Attach event listeners
//   buttons.forEach((button) => {
//     button.addEventListener('click', () => {
//       const isActive = button.classList.contains('active');
//       resetButtonStyles();
//       if (!isActive) updateButtonState(button, true);
//       loadEvents(1);
//       loadMoreButton.style.display = 'block';
//     });
//   });
//   dropdown.addEventListener('change', () => {
//     resetButtonStyles();
//     loadEvents(1);
//     loadMoreButton.style.display = 'block';
//   });
//   loadMoreButton.addEventListener('click', () => {
//     const page = parseInt(loadMoreButton.dataset.page || '1', 10);
//     loadEvents(page, true);
//   });
//   // Initial load
//   loadEvents();
// }
// export default function initEventCalendar() {
//   const grid = document.getElementById('events-grid') as HTMLElement | null;
//   const loadMoreButton = document.getElementById(
//     'load-more-events'
//   ) as HTMLButtonElement | null;
//   const dropdownToggle = document.getElementById(
//     'dropdown-toggle'
//   ) as HTMLButtonElement | null;
//   const dropdownArrow = document.getElementById(
//     'dropdown-arrow'
//   ) as HTMLElement | null;
//   const dropdownOptions = document.getElementById(
//     'dropdown-options'
//   ) as HTMLElement | null;
//   const dropdownSelected = document.getElementById(
//     'dropdown-selected'
//   ) as HTMLElement | null;
//   const buttons = document.querySelectorAll<HTMLButtonElement>(
//     '.filter-controls button'
//   );
//   const noEventsNotice = document.getElementById(
//     'no-events-notice'
//   ) as HTMLElement | null;
//   if (
//     !grid ||
//     !loadMoreButton ||
//     !dropdownToggle ||
//     !dropdownArrow ||
//     !dropdownOptions ||
//     !dropdownSelected ||
//     !noEventsNotice
//   ) {
//     console.error('Missing required DOM elements for event calendar.');
//     return;
//   }
//   const allEvents = Array.from(grid.children) as HTMLElement[]; // Store all event cards
//   let currentPage = 1;
//   const perPage = 6;
//   let selectedType = ''; // Initially set to "View All Events"
//   const renderEvents = (
//     events: HTMLElement[],
//     page: number,
//     append = false
//   ) => {
//     const start = 0;
//     const end = page * perPage;
//     const paginatedEvents = events.slice(start, end);
//     if (!append) grid.innerHTML = ''; // Clear grid if not appending
//     paginatedEvents.forEach((event) => grid.appendChild(event));
//     // Show/hide "Load More" button
//     loadMoreButton.style.display = end < events.length ? 'block' : 'none';
//     noEventsNotice.style.display = events.length === 0 ? 'block' : 'none';
//   };
//   const filterEvents = () => {
//     const selectedMembership =
//       document.querySelector<HTMLButtonElement>(
//         '.filter-controls button.active'
//       )?.dataset.filter || '';
//     // Filter events based on dropdown and button selections
//     const filteredEvents = allEvents.filter((event) => {
//       const eventType = event.dataset.eventType || '';
//       const eventMembership = event.dataset.membership || '';
//       const typeMatch =
//         !selectedType ||
//         selectedType === 'View All Events' ||
//         eventType === selectedType;
//       const membershipMatch =
//         !selectedMembership || eventMembership === selectedMembership;
//       return typeMatch && membershipMatch;
//     });
//     currentPage = 1; // Reset pagination
//     renderEvents(filteredEvents, currentPage);
//   };
//   // Button click event handlers
//   buttons.forEach((button) => {
//     button.addEventListener('click', () => {
//       const isActive = button.classList.contains('active');
//       // Toggle active state for buttons
//       buttons.forEach((btn) => btn.classList.remove('active', 'bg-[#ffaeae]'));
//       if (!isActive) button.classList.add('active', 'bg-[#ffaeae]');
//       filterEvents();
//     });
//   });
//   // Toggle dropdown visibility
//   dropdownToggle.addEventListener('click', () => {
//     const isOpen = dropdownOptions.classList.contains('hidden');
//     dropdownOptions.classList.toggle('hidden', isOpen); // Toggle hidden class
//     dropdownArrow.classList.toggle('rotate-180', !isOpen); // Rotate arrow
//   });
//   // Dropdown option selection
//   dropdownOptions.addEventListener('click', (event) => {
//     const option = event.target as HTMLElement;
//     if (option.tagName === 'LI') {
//       selectedType = option.dataset.value || ''; // Update the selected value
//       dropdownSelected.textContent = option.textContent; // Update displayed text
//       dropdownOptions.classList.add('hidden'); // Close dropdown
//       dropdownArrow.classList.remove('rotate-180'); // Reset arrow
//       buttons.forEach((btn) => btn.classList.remove('active')); // Reset button states
//       filterEvents(); // Apply filters
//     }
//   });
//   // Close dropdown when clicking outside
//   document.addEventListener('click', (event) => {
//     if (
//       !dropdownToggle.contains(event.target as Node) &&
//       !dropdownOptions.contains(event.target as Node)
//     ) {
//       dropdownOptions.classList.add('hidden'); // Close dropdown
//       dropdownArrow.classList.remove('rotate-180'); // Reset arrow
//     }
//   });
//   // Load more button event handler
//   loadMoreButton.addEventListener('click', () => {
//     currentPage++;
//     const selectedMembership =
//       document.querySelector<HTMLButtonElement>(
//         '.filter-controls button.active'
//       )?.dataset.filter || '';
//     const filteredEvents = allEvents.filter((event) => {
//       const eventType = event.dataset.eventType || '';
//       const eventMembership = event.dataset.membership || '';
//       const typeMatch = !selectedType || eventType === selectedType;
//       const membershipMatch =
//         !selectedMembership || eventMembership === selectedMembership;
//       return typeMatch && membershipMatch;
//     });
//     renderEvents(filteredEvents, currentPage, true);
//   });
//   // Initial render
//   renderEvents(allEvents, currentPage);
// }
// Include GSAP in your project if it's not already added
// <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/gsap.min.js"></script>
import { gsap } from 'gsap';
export default function initEventCalendar() {
    const grid = document.getElementById('events-grid');
    const loadMoreButton = document.getElementById('load-more-events');
    const dropdownToggle = document.getElementById('dropdown-toggle');
    const dropdownArrow = document.getElementById('dropdown-arrow');
    const dropdownOptions = document.getElementById('dropdown-options');
    const dropdownSelected = document.getElementById('dropdown-selected');
    const buttons = document.querySelectorAll('.filter-controls button');
    const memberButtons = document.querySelectorAll('.member-buttons button');
    const noEventsNotice = document.getElementById('no-events-notice');
    if (!grid ||
        !loadMoreButton ||
        !dropdownToggle ||
        !dropdownArrow ||
        !dropdownOptions ||
        !dropdownSelected ||
        !noEventsNotice) {
        console.error('Missing required DOM elements for event calendar.');
        return;
    }
    let allEvents = Array.from(grid.children);
    let currentPage = 1;
    const perPage = 6;
    let selectedType = '';
    /** Initial Render (Only First 6 Events) **/
    const initialRender = () => {
        currentPage = 1;
        renderEvents(allEvents.slice(0, perPage), false);
        loadMoreButton.style.display =
            perPage < allEvents.length ? 'block' : 'none';
    };
    /** Reset Calendar & Dropdown to Initial State **/
    const resetCalendar = () => {
        selectedType = '';
        dropdownSelected.textContent = 'Event Type'; // Reset dropdown text
        // Reset dropdown background to white
        dropdownToggle.classList.remove('bg-[#de212a]', 'text-white');
        dropdownToggle.classList.add('bg-white', 'text-black');
        buttons.forEach((btn) => btn.classList.remove('active')); // Remove any active filters
        renderEvents(allEvents.slice(0, perPage), false);
        loadMoreButton.style.display =
            perPage < allEvents.length ? 'block' : 'none';
    };
    /** Render Events with GSAP Animation **/
    const renderEvents = (events, append = false) => {
        if (!append) {
            gsap.to(grid.children, {
                opacity: 0,
                scale: 0.5,
                duration: 0.3,
                ease: 'power1.inOut',
                onComplete: () => {
                    grid.innerHTML = '';
                    events.forEach((event) => grid.appendChild(event));
                    gsap.fromTo(events, { opacity: 0, scale: 0.5, y: 10 }, {
                        opacity: 1,
                        scale: 1,
                        y: 0,
                        duration: 0.5,
                        ease: 'back.out(0.3)',
                        stagger: 0.1,
                    });
                },
            });
        }
        else {
            events.forEach((event) => grid.appendChild(event));
            gsap.fromTo(events, { opacity: 0, scale: 0.8 }, {
                opacity: 1,
                scale: 1,
                duration: 0.4,
                stagger: 0.1,
                ease: 'back.out(0.3)',
            });
        }
        noEventsNotice.style.display = events.length === 0 ? 'block' : 'none';
    };
    /** Membership Filter Buttons **/
    memberButtons.forEach((button) => {
        button.addEventListener('click', () => {
            const isActive = button.classList.contains('active');
            memberButtons.forEach((btn) => btn.classList.remove('active', 'bg-[#ffaeae]'));
            if (!isActive)
                button.classList.add('active', 'bg-[#ffaeae]');
            filterEvents();
        });
    });
    // Toggle dropdown visibility
    dropdownToggle.addEventListener('click', () => {
        const isOpen = dropdownOptions.classList.contains('hidden');
        dropdownOptions.classList.toggle('hidden'); // Toggle hidden class
        dropdownArrow.classList.toggle('rotate-180'); // Rotate arrow
    });
    /** Handle Dropdown Selection **/
    dropdownOptions.addEventListener('click', (event) => {
        const option = event.target;
        if (option.tagName === 'LI') {
            selectedType = option.dataset.value || '';
            if (selectedType === 'View all') {
                dropdownSelected.textContent = 'Event Type';
                dropdownToggle.classList.remove('bg-[#de212a]', 'text-white');
                dropdownToggle.classList.add('bg-white', 'text-black');
                resetCalendar();
            }
            else {
                dropdownSelected.textContent = option.textContent;
                dropdownToggle.classList.remove('bg-white', 'text-black');
                dropdownToggle.classList.add('bg-[#de212a]', 'text-white', 'hover:bg-[#de212a]');
                filterEvents();
            }
            dropdownOptions.classList.add('hidden');
            dropdownArrow.classList.remove('rotate-180');
        }
    });
    /** Close Dropdown When Clicking Outside **/
    document.addEventListener('click', (event) => {
        if (!dropdownToggle.contains(event.target) &&
            !dropdownOptions.contains(event.target)) {
            dropdownOptions.classList.add('hidden');
            dropdownArrow.classList.remove('rotate-180');
        }
    });
    /** Filter Events Based on Selection **/
    const filterEvents = () => {
        const selectedMembership = document.querySelector('.filter-controls button.active')?.dataset.filter || '';
        const filteredEvents = allEvents.filter((event) => {
            const eventType = event.dataset.eventType || '';
            const eventMembership = event.dataset.membership || '';
            const typeMatch = !selectedType ||
                selectedType === 'View all' ||
                eventType === selectedType;
            const membershipMatch = !selectedMembership || eventMembership === selectedMembership;
            return typeMatch && membershipMatch;
        });
        currentPage = 1;
        renderEvents(filteredEvents.slice(0, perPage), false);
        loadMoreButton.style.display =
            filteredEvents.length > perPage ? 'block' : 'none';
    };
    /** Load More Events **/
    loadMoreButton.addEventListener('click', async () => {
        currentPage++;
        const selectedMembership = document.querySelector('.filter-controls button.active')?.dataset.filter || '';
        const filteredEvents = allEvents.filter((event) => {
            const eventType = event.dataset.eventType || '';
            const eventMembership = event.dataset.membership || '';
            const typeMatch = !selectedType ||
                selectedType === 'View all' ||
                eventType === selectedType;
            const membershipMatch = !selectedMembership || eventMembership === selectedMembership;
            return typeMatch && membershipMatch;
        });
        const start = (currentPage - 1) * perPage;
        const end = start + perPage;
        const newEvents = filteredEvents.slice(start, end);
        if (newEvents.length === 0) {
            loadMoreButton.style.display = 'none';
            return;
        }
        renderEvents(newEvents, true);
        if (end >= filteredEvents.length) {
            loadMoreButton.style.display = 'none';
        }
    });
    /** Initial Load **/
    initialRender();
}
