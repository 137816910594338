// export default function initFacilitatorFilter(): void {
//   const skillFilter = document.getElementById(
//     'skill-filter'
//   ) as HTMLSelectElement | null;
//   if (!skillFilter) return;
//   skillFilter.addEventListener('change', () => {
//     const selectedSkill = skillFilter.value.toLowerCase();
//     const cards = document.querySelectorAll(
//       '.facilitator-card'
//     ) as NodeListOf<HTMLElement>;
//     cards.forEach((card) => {
//       // Read the comma-separated skills from the data attribute
//       const skills = card.dataset.skills
//         ? card.dataset.skills.toLowerCase()
//         : '';
//       // If no skill is selected or the card's skills include the selected skill, show the card
//       card.style.display =
//         !selectedSkill || skills.includes(selectedSkill) ? '' : 'none';
//     });
//   });
// }
export default function initFacilitatorFilter() {
    const dropdownToggle = document.getElementById('facilitator-dropdown-toggle');
    const dropdownSelected = document.getElementById('facilitator-dropdown-selected');
    const dropdownOptions = document.getElementById('facilitator-dropdown-options');
    const dropdownArrow = document.getElementById('facilitator-dropdown-arrow');
    const facilitatorCards = document.querySelectorAll('.facilitator-card');
    if (!dropdownToggle || !dropdownSelected || !dropdownOptions)
        return;
    // Toggle dropdown visibility when clicking the toggle button
    dropdownToggle.addEventListener('click', () => {
        dropdownOptions.classList.toggle('hidden');
        dropdownArrow?.classList.toggle('rotate-180');
    });
    // Handle dropdown option selection
    dropdownOptions.querySelectorAll('li').forEach((option) => {
        option.addEventListener('click', () => {
            const selectedValue = option.getAttribute('data-value') || '';
            dropdownSelected.textContent =
                selectedValue === '' ? 'All Skills' : selectedValue;
            dropdownOptions.classList.add('hidden');
            dropdownArrow?.classList.remove('rotate-180');
            // Filter facilitator cards based on the selected skill
            facilitatorCards.forEach((card) => {
                const cardSkills = card.getAttribute('data-skills')?.toLowerCase() || '';
                card.style.display =
                    selectedValue === '' ||
                        cardSkills.includes(selectedValue.toLowerCase())
                        ? ''
                        : 'none';
            });
        });
    });
    // Close the dropdown if clicking outside of it
    document.addEventListener('click', (e) => {
        if (!dropdownToggle.contains(e.target) &&
            !dropdownOptions.contains(e.target)) {
            dropdownOptions.classList.add('hidden');
            dropdownArrow?.classList.remove('rotate-180');
        }
    });
}
